<template>
	<span>
		<div v-show="!editing" :class="{ white: itemval == DEFAULT }" @dblclick="setEditing(true)">{{ itemval }}</div>
		<!-- // @keyup.enter.prevent="setEditing(false)" -->
		<textarea v-focus="editing" class="input" v-show="editing" v-model="val" @blur="setEditing(false)"></textarea>
	</span>
</template>

<script>
import { focus } from 'vue-focus';

export default {
	data: () => ({
		DEFAULT: '...',
		val: '',
		prvVal: '',
		editing: false,
	}),

	props: ['item'],

	computed: {
		itemval: function () {
			if (this.item == '') return this.DEFAULT;
			else return this.item;
		},
	},

	directives: {
		focus: focus,
	},

	methods: {
		setEditing: function (editing) {
			this.editing = editing;
			if (editing) {
				this.val = this.item;
			} else {
				if (this.val != this.prvVal) {
					this.update(this.val);
					this.prvVal = this.val;
				}
			}
		},

		update: function (e) {
			// console.log('update', e);
			this.$emit('updated', this.val);
		},
	},
};
</script>

<style scoped>
.white {
	color: white;
}

div {
	border: 1px solid #eeeeee;
}

textarea {
	width: 100%;
	resize: auto;
}
</style>
